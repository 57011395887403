
.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 500px;
  background-color: var(--colors-surface);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  padding: 0 16px;
  border-bottom: 1px solid var(--colors-decorationSubtle);

  h1 {
    flex-grow: 1;
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
}

.content {
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-flow: column;
}

@media (max-width: 700px) {
  .modal {
    width: 100%;
    top: 64px;
    border-radius: 8px 8px 0 0;
  }
}